<template>
  <div class="mx-5 mx-md-16 px-md-16">
    <span class="d-none">{{ updateList }}</span>

    <v-data-table
      :headers="headers"
      :items="orders.items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :search="search"
      hide-default-footer
      class="elevation-1 rounded-lg"
      @page-count="pageCount = $event"
    >
    </v-data-table>
    <div class="pt-2 d-flex flex-column flex-md-row align-center justify-end">
      <span class="font-quicksand">Filas por páginas: </span>
      <span style="width: 50px" class="mx-md-2">
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPages"
          class="font-quicksand"
        ></v-select>
      </span>
      <span class="font-quicksand"
        >1-{{ itemsPerPage }} de {{ orders.totalItems }}
      </span>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="pageVisible"
        color="red"
        class="font-work-sans"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
const OrderRepository = Repository.get('orders')

export default {
  name: 'ActiveOrdersCli',
  props: ['search', 'newItem'],
  data: () => ({
    orders: {},
    itemsPerPage: 7,
    pageVisible: 5,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'C.I. paciente',
        align: 'center',
        class:
          'primary white--text rounded-tl-lg font-weight-bold text-subtitle-2',
        value: 'patientIdNumber',
      },
      {
        text: 'Fecha',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'date',
      },
      {
        text: 'Doctor',
        align: 'center',
        class:
          'primary white--text font-weight-bold text-subtitle-2 rounded-tr-lg',
        value: 'doctorName',
      },
    ],
    itemsPerPages: [7, 10, 20, 30, 50, 100],
  }),
  computed: {
    updateList: async function () {
      if (this.newItem) {
        await this.loadData()
      }
    },
  },
  mounted: async function () {
    await this.loadData()
  },
  methods: {
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = await OrderRepository.get()
        this.orders = res.data.data
      } catch (error) {
        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
  },
}
</script>
