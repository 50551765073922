<template>
  <div>
    <orders />
  </div>
</template>

<script>
import Orders from '@/components/catalog/Orders/Orders.vue'

export default {
  components: { Orders },
  name: 'Order',
}
</script>
