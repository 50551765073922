<template>
  <div>
    <v-dialog
      v-model="isActive"
      persistent
      transition="dialog-transition"
      width="325"
      height="180"
    >
      <span class="d-none">{{ change }}</span>

      <v-card class="rounded-lg">
        <v-card-title>
          <v-row>
            <v-col cols="11" class="px-0">
              <h6 class="text-subtitle-1 font-weight-bold text-center">
                <span class="font-work-sans ml-5"
                  >{{ edit ? 'Modificar ' : 'Crear ' }}Orden</span
                >
              </h6>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-btn color="primary" icon @click="$emit('dismiss')"
                ><v-icon small>fa-times</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <label for="" class="ml-4 black--text font-weight-medium">Id</label>
            <v-text-field
              placeholder="Id"
              color="primary"
              rounded
              prepend-inner-icon="fa-file-alt"
              :rules="[rules.required, rules.integer]"
              required
              outlined
              :disabled="edit"
              class="mt-2 mb-n3 font-work-sans"
              v-model="orderData.id"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Nombre del Doctor</label
            >
            <v-text-field
              placeholder="Doctor"
              color="primary"
              rounded
              prepend-inner-icon="fa-user-md"
              :rules="[rules.required, rules.alfanumeric]"
              required
              outlined
              class="mt-2 mb-n3"
              v-model="orderData.doctorName"
            ></v-text-field>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Fecha</label
            >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="orderData.date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="birthday"
                  v-model="orderData.date"
                  solo
                  rounded
                  prepend-inner-icon="fa-calendar-day"
                  placeholder="Fecha de la orden"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="font-quicksand mb-n3"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="orderData.date"
                no-title
                scrollable
                :max="today"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(orderData.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

            <label for="" class="ml-4 black--text font-weight-medium"
              >Paciente</label
            >
            <v-select
              ref="info"
              placeholder="Paciente"
              :rules="[rules.required]"
              v-model="orderData.patientIdNumber"
              prepend-inner-icon="fa-user-injured"
              :items="patients"
              outlined
              rounded
              class="mt-2 mb-n3"
            ></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-n4">
          <v-btn
            color="primary"
            outlined
            class="rounded-lg px-4"
            @click="
              () => {
                $refs.form.reset()
                $emit('dismiss')
              }
            "
          >
            <v-icon small>fa-times</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            class="rounded-lg elevation-0 px-4"
            @click="submit"
            :loading="loading"
          >
            <v-icon small>fa-check</v-icon>
            <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-edit
      :item="item"
      :dialog="dialogEdit"
      @dismiss="
        () => {
          dialogEdit = false
        }
      "
      @accept="activeAlerts()"
    />
  </div>
</template>

<script>
import { format } from 'date-fns'
import Swal from 'sweetalert2'
import Repository from '@/repositories/RepositoryFactory'
import DialogEdit from '@/components/DialogEdit.vue'
const OrderRepository = Repository.get('orders')
const PatientRepository = Repository.get('patients')

export default {
  components: { DialogEdit },
  name: 'FormDialogOrder',
  props: {
    order: {
      type: Object,
      default: { id: '', doctorName: '', date: '', patientIdNumber: '' },
    },
    dialog: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },
  data: () => ({
    isActive: false,
    valid: false,
    menu: false,
    loading: false,
    orderData: { id: '', doctorName: '', date: '', patientIdNumber: '' },
    dialogEdit: false,
    item: {},
    patients: [],
    today: format(new Date(), 'yyyy-MM-dd'),
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      integer: (value) => {
        const pattern = /[^0-9]/
        return !pattern.test(value) || 'Campo inválido.'
      },
      alfanumeric: (value) => {
        const pattern = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
        return !pattern.test(value) || 'Campo inválido.'
      },
    },
  }),
  computed: {
    change: async function () {
      try {
        this.isActive = await this.dialog
        if (this.order) {
          this.orderData = Object.assign({}, this.order)
          this.orderData.date = format(new Date(this.order.date), 'yyyy-MM-dd')
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted: async function () {
    await this.getPatients()
  },
  methods: {
    getPatients: async function () {
      try {
        const res = (await PatientRepository.get()).data.data
        this.patients = Array.from(res.items, (item) => {
          return {
            text: `${item.firstName} ${item.lastName}` || item.idNumber,
            value: item.idNumber,
          }
        })
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },

    submit: function () {
      this.$refs.form.validate()

      setTimeout(() => {
        if (this.valid) {
          if (!this.edit) this.activeAlerts()
          else {
            this.item = {
              type: 'orden',
              id: this.order.patientIdNumber,
              name: this.order.doctorName,
            }
            this.dialogEdit = true
          }
        }
      })
    },

    activeAlerts: async function () {
      this.dialogEdit = false

      const data = {
        type: 'success',
        title: 'Petición exitosa',
        text: this.edit
          ? 'Orden actualizada con éxito'
          : 'Orden registrada con éxito',
      }

      this.loading = true

      try {
        const value = Object.assign({}, this.orderData)

        let date = new Date(value.date)
        date.setDate(date.getDate() + 1)
        date = format(date, 'MM-dd-yyyy')

        value.date = date

        this.edit
          ? await OrderRepository.update(value, value.id)
          : await OrderRepository.create(value)

        this.$store.dispatch('alerts/activeAlerts', data)
        this.$refs.form.reset()
        this.$emit('dismiss')
      } catch (error) {
        const response = error.response
          ? error.response.data.data ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }

      this.loading = false
    },
  },
}
</script>
